import React, { useEffect } from "react";
import "./Dashboard.css";

// Components
import ThermosStatus from "../../components/ThermosStatus/ThermosStatus";

const Dashboard = (props) => {
  useEffect(() => {
    props.handlePage("Dashboard");
  }, [props]);

  return (
    <div className="Dashboard">
      <ThermosStatus {...props.coffeeStatus} avgRating={props.avgRating} />
    </div>
  );
};

export default Dashboard;
