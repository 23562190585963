import React, { useState, useEffect } from "react";
import { getUsers, deleteUser } from "../../api/apiCalls";
import "./ManageUsersComponent.css";

// functional component that renders the users in a table
const ManageUsersComponent = ({ editUserId }) => {
  const [users, setUsers] = useState([]);

  // set the users state to the users returned from the api
  useEffect(() => {
    getUsers().then((res) => {
      setUsers(res.data);
    });
  }, []);

  // function that handles the delete button
  const handleDeleteUser = (UserId) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      deleteUser(UserId).then(() => {
        getUsers().then((res) => {
          setUsers(res.data);
        });
      });
    }
  };

  // function that handles the edit button
  const handleEditUser = (UserId) => {
    editUserId(UserId);
  };

  return (
    <div className="ManageUsersComponent">
      {users.map((user) => (
        <div className="card" key={user.username}>
          <div className="username">
            <p>{user.username}</p>
          </div>
          <button
            className="btn btn-primary"
            onClick={() => handleEditUser(user._id)}
          >
            Edit
          </button>
          <button
            className="btn btn-danger"
            onClick={() => handleDeleteUser(user._id)}
          >
            Delete
          </button>
        </div>
      ))}
    </div>
  );
};

export default ManageUsersComponent;
