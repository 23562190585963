import React from "react";
import "./Hamburger.css";

const Hamburger = (props) => {
  return (
    <div className="Hamburger" onClick={props.handleHamburger}>
      <div className="HamburgerLine"></div>
      <div className="HamburgerLine"></div>
      <div className="HamburgerLine"></div>
    </div>
  );
};

export default Hamburger;
