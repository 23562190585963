import React from "react";
import "./MyRatingsCard.css";
import pen from "../../images/pen.svg";
import moment from "moment";

// Components
import YourStars from "../YourStars/YourStars";

const MyRatingsCard = (props) => {
  return (
    <div className="MyRatingsCard">
      <div className="text">
        <button
          onClick={() => {
            props.triggerPopup(props.data.CoffeeName, props.data.BrewId);
          }}
        >
          <img src={pen} alt="Edit my ratings"></img>
        </button>
        <p>Coffee: {props.data.CoffeeName}</p>
        <p>Grinding: {props.data.Grinding}</p>
        <p>Water: {props.data.WaterLevel} L</p>
        <p>{moment(props.data.CreatedAt).format("dddd")}</p>
        <YourStars rating={props.data.rating} />
      </div>
    </div>
  );
};

export default MyRatingsCard;
