import React from "react";
import "./LoginForm.css";
import { NavLink } from "react-router-dom";

// Class component of Login Form that sends the credentials to parent
export default class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
    };
  }

  // Handles form submit
  handleSubmit = (event) => {
    event.preventDefault();
    const { username, password } = this.state;
    this.props.onSubmit(username, password);
    this.setState({
      username: "",
      password: "",
    });
  };

  // Controls form state
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    return (
      <form className="LoginForm" onSubmit={this.handleSubmit}>
        <label>
          Username
          <br />
          <input
            type="text"
            name="username"
            value={this.state.username}
            onChange={this.handleChange}
          />
        </label>
        <br />
        <label>
          Password
          <br />
          <input
            type="password"
            name="password"
            value={this.state.password}
            onChange={this.handleChange}
          />
        </label>
        <br />
        <input type="submit" value="Login" id="submit" />
        <div className="registerLink">
          <p>Don't have an account?</p>
          <NavLink to="/register">Register here</NavLink>
        </div>
      </form>
    );
  }
}
