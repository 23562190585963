import React, { useState, useEffect } from "react";
import "./BrewHistoryCard.css";
import pen from "../../images/pen.svg";
import star from "../../images/star.svg";
import moment from "moment";
import { fetchRatings, checkRating } from "../../api/apiCalls";
import { default as AuthContext } from "../../utils/AuthContextProvider";

// Components
import YourStars from "../YourStars/YourStars";

const BrewHistoryCard = (props) => {
  const auth = React.useContext(AuthContext);
  const [AvgRating, setAvgRating] = useState(0);
  const [AlreadyRated, setAlreadyRated] = useState(false);

  // Fetch ratings
  useEffect(() => {
    fetchRatings(props.data._id).then((response) => {
      calcAverageRating(response.data);
    });
    checkRating(auth.user.id, props.data._id).then(
      (response) => response.data.length !== 0 && setAlreadyRated(true)
    );
  }, [props.data._id, auth.user.id]);

  // Calculate average rating
  const calcAverageRating = (data) => {
    const arr = [];
    data.map((item) => arr.push(item.rating));
    arr.length !== 0 &&
      setAvgRating(Math.round(arr.reduce((a, b) => a + b) / arr.length));
  };

  return (
    <div className="BrewHistoryCard">
      <div className="text">
        <button
          onClick={() => {
            props.triggerPopup(
              props.data.CoffeeName,
              props.data.grindSetting,
              props.data.litersWater,
              props.data.CreatedAt,
              props.data._id
            );
          }}
        >
          <img src={pen} alt="Edit my ratings"></img>
        </button>
        {AlreadyRated && (
          <div className="check">
            <img src={star} alt="Edit my ratings"></img>
          </div>
        )}
        <p>Coffee: {props.data.CoffeeName}</p>
        <p>Grinding: {props.data.grindSetting}</p>
        <p>Water: {props.data.litersWater} L</p>
        <p>{moment(props.data.CreatedAt).format("dddd")}</p>
        <YourStars rating={AvgRating} />
      </div>
    </div>
  );
};

export default BrewHistoryCard;
