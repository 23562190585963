// imports
import React, { useEffect } from "react";
import { useContext } from "react";
import "./AddBeans.css";
import { default as AuthContext } from "../../utils/AuthContextProvider";

// Components
import AddBeansForm from "../../components/AddBeansForm/AddBeansForm";
import CoffeeBeanList from "../../components/CoffeeBeanList/CoffeeBeanList";

// Functional component page that contains the add beans components
const AddBeans = (props) => {
  useEffect(() => {
    props.handlePage("Add beans");
  }, [props]);

  // Get the auth context
  const auth = useContext(AuthContext);

  // if user is admin, show the add beans form as well as the coffee bean list
  return (
    <div className="AddBeans">
      {auth.isAuth && auth.user.role[0] === "admin" ? (
        <>
          <AddBeansForm />
          <CoffeeBeanList />
        </>
      ) : (
        <CoffeeBeanList />
      )}
    </div>
  );
};

export default AddBeans;
