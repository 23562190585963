import React, { useState, useEffect } from "react";
import "./Coffeemug.css";
import coffeemug from "../../images/mug.svg";

const Coffeemug = (props) => {
  const [Height, setHeight] = useState("dashboard");

  useEffect(() => {
    handleCoffeeHeight();
  });

  // Handles the styling of coffeheight in mug
  const handleCoffeeHeight = () => {
    switch (props.litersWater) {
      case 2.2:
        setHeight("50%");
        break;
      case 1.1:
        setHeight("30%");
        break;
      case 0.5:
        setHeight("15%");
        break;
      default:
        setHeight("0%");
        break;
    }
  };

  return (
    <div className="Coffeemug">
      <div style={{ height: `${Height}` }}></div>
      <img src={coffeemug} alt="Coffeemug"></img>
    </div>
  );
};

export default Coffeemug;
