// imports
import React, { useEffect } from "react";
import "./Register.css";
import { useNavigate } from "react-router-dom";

// Components
import RegisterForm from "../../components/RegisterForm/RegisterForm";

// functional component page that contains the registration form component
const Register = (props) => {
  useEffect(() => {
    props.handlePage("Register");
  }, [props]);

  const navigate = useNavigate();
  // navigate to login when registration is successful
  const handleClick = () => {
    navigate("/login");
  };

  return (
    <div className="Register">
      <RegisterForm onSubmit={handleClick} />
    </div>
  );
};

export default Register;
