import React, { useState, useEffect } from "react";
import moment from "moment";
import "./ThermosStatus.css";

// Components
import Coffeemug from "../Coffeemug/Coffeemug";
import Stars from "../Stars/Stars";

const ThermosStatus = (props) => {
  const [Temperature, setTemperature] = useState("Cold");
  useEffect(() => {
    handleStatus(moment().diff(props.CreatedAt, "minutes"));
  });

  // Handles the temperature status
  const handleStatus = (timeSince) => {
    switch (true) {
      case timeSince < 30:
        setTemperature("Hot");
        break;
      case timeSince < 60:
        setTemperature("Warm");
        break;
      case timeSince < 90:
        setTemperature("Cold");
        break;
      default:
        break;
    }
  };

  return (
    <div className="ThermosStatus">
      <div className="ThermosTitle">
        <h3>Brewed by {props.User}</h3>
        <h2>{props.CoffeeName}</h2>
        <Stars avgRating={props.avgRating} />
      </div>
      <div className="CoffeeStats">
        <div className="Left">
          <Coffeemug litersWater={props.litersWater} />
        </div>
        <div className="Right">
          <div className="CoffeeStat">
            <p>Brewed:</p>
            <p>{moment(props.CreatedAt).format("dddd")}</p>
          </div>
          <div className="CoffeeStat">
            <p>Time:</p>
            <p>{moment(props.CreatedAt).format("LT")}</p>
          </div>
          <div className="CoffeeStat">
            <p>Liters:</p>
            <p>{props.litersWater} L</p>
          </div>
          <div className="CoffeeStat">
            <p>Left:</p>
            <p>1.1 L</p>
          </div>
          <div className="CoffeeStat">
            <p>Status:</p>
            <p>{Temperature}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThermosStatus;
