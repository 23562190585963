import React, { Component } from "react";
import {
  getBrews,
  getBeans,
  checkRating,
  postRating,
  updateRating,
  fetchRatings,
} from "../api/apiCalls";
import AuthContext from "../utils/AuthContextProvider";

function CoffeeHOC(WrappedComponent) {
  class CoffeeComponent extends Component {
    static contextType = AuthContext;
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        coffeeStatus: {},
        coffeeBeans: [],
        rating: 0,
        avgRating: 0,
      };
      this.fetchData = this.fetchData.bind(this);
      this.setRating = this.setRating.bind(this);
    }

    // Fetch data each time component is loaded
    componentDidMount() {
      this.setState({ loading: true });
      this.fetchData();
    }

    // Updates when props are changed
    componentDidUpdate(prevProps) {
      if (prevProps.UpdatedCoffeeStatus !== this.props.UpdatedCoffeeStatus) {
        this.fetchData();
      }
    }

    // Calculates average rating
    calcAvergareRating = (data) => {
      const arr = [];
      data.map((item) => arr.push(item.rating));
      this.setState({ avgRating: arr.reduce((a, b) => a + b) / arr.length });
    };

    // Fetch data with help from api calls
    fetchData = async () => {
      this.setState({ loading: true });
      // Fetch latest brew
      await getBrews().then((response) => {
        this.setState({ coffeeStatus: response.data[0] });
      });

      // Fetch every bean and add to array in state
      await getBeans().then((response) => {
        this.setState({ coffeeBeans: [] });
        response.data.map((bean) =>
          this.setState((prevState) => ({
            coffeeBeans: [...prevState.coffeeBeans, bean.name],
          }))
        );
      });

      // Fetch all ratings for current brew
      await fetchRatings(this.state.coffeeStatus._id).then((response) => {
        response.data.length !== 0 && this.calcAvergareRating(response.data);
      });

      this.setState({ loading: false });
    };

    // Post a new rating. If allready voted on that brew, update instead
    setRating = async (rating) => {
      this.setState({ rating: rating });
      const newRating = async () => {
        await postRating(
          this.state.coffeeStatus._id,
          this.context.user.id,
          this.state.rating,
          this.state.coffeeStatus.CoffeeName,
          this.state.coffeeStatus.grindSetting,
          this.state.coffeeStatus.litersWater,
          this.state.coffeeStatus.CreatedAt
        );
      };

      // Check is user has rated. If so, update rating, if not, create a rating
      await checkRating(this.context.user.id, this.state.coffeeStatus._id).then(
        (response) => {
          response.data.length === 0
            ? newRating()
            : updateRating(
                this.context.user.id,
                this.state.coffeeStatus._id,
                this.state.rating,
                this.state.coffeeStatus.CoffeeName,
                this.state.coffeeStatus.grindSetting,
                this.state.coffeeStatus.litersWater,
                this.state.coffeeStatus.CreatedAt
              );
        }
      );
    };

    render() {
      return (
        <>
          {!this.state.loading ? (
            <WrappedComponent
              {...this.state}
              handlePage={this.props.handlePage}
              setUpdatedCoffeeStatus={this.props.setUpdatedCoffeeStatus}
              setRating={this.setRating}
              fetchData={this.fetchData}
            />
          ) : (
            "Loading..."
          )}
        </>
      );
    }
  }

  return CoffeeComponent;
}

export default CoffeeHOC;
