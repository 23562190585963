import React, { useState, useEffect } from "react";
import "./Stars.css";
import { ReactComponent as Star } from "../../images/star.svg";

const Stars = (props) => {
  const [Stars, setStars] = useState([
    <Star key={1} fill="#D7A86E" />,
    <Star key={2} fill="#D7A86E" />,
    <Star key={3} fill="#D7A86E" />,
    <Star key={4} fill="#D7A86E" />,
    <Star key={5} fill="#D7A86E" />,
  ]);

  const [RoundedRating, setRoundedRating] = useState(0);

  // Displays amount of colored stars based on rating
  useEffect(() => {
    setRoundedRating(Math.round(props.avgRating));
    switch (RoundedRating) {
      case 1:
        setStars([
          SelectedStar(1),
          UnselectedStar(2),
          UnselectedStar(3),
          UnselectedStar(4),
          UnselectedStar(5),
        ]);
        break;
      case 2:
        setStars([
          SelectedStar(1),
          SelectedStar(2),
          UnselectedStar(3),
          UnselectedStar(4),
          UnselectedStar(5),
        ]);
        break;
      case 3:
        setStars([
          SelectedStar(1),
          SelectedStar(2),
          SelectedStar(3),
          UnselectedStar(4),
          UnselectedStar(5),
        ]);
        break;
      case 4:
        setStars([
          SelectedStar(1),
          SelectedStar(2),
          SelectedStar(3),
          SelectedStar(4),
          UnselectedStar(5),
        ]);
        break;
      case 5:
        setStars([
          SelectedStar(1),
          SelectedStar(2),
          SelectedStar(3),
          SelectedStar(4),
          SelectedStar(5),
        ]);
        break;
      default:
        break;
    }
  }, [props.avgRating, RoundedRating]);

  const UnselectedStar = (item) => {
    return <Star key={item} fill="#D7A86E" />;
  };

  const SelectedStar = (item) => {
    return <Star key={item} fill="#8E3200" />;
  };

  return <div className="Stars">{Stars}</div>;
};

export default Stars;
