import axios from "./axios";

// Create a new brew
const createBrew = (CoffeeName, CoffeeType, grindSetting, litersWater, User) =>
  axios.post(
    "/v1/api/brew",
    CoffeeName,
    CoffeeType,
    grindSetting,
    litersWater,
    User
  );

// Create a new bean
const createBean = (name, brand, roast, nok, grams) =>
  axios.post("/v1/api/bean", {
    name: name,
    brand: brand,
    roast: roast,
    nok: nok,
    grams: grams,
  });

// Find latest brew
const getBrews = () => {
  return axios.get("/v1/api/brew/latest");
};

// Fetch all brews
const getAllBrews = () => {
  return axios.get("/v1/api/brew");
};

// Find every bean
const getBeans = () => {
  return axios.get("/v1/api/bean");
};

// Check if you have rated
const checkRating = async (UserId, BrewId) => {
  return axios.get(`/v1/api/rate/rated/${UserId}/${BrewId}`);
};

// Post a rating
const postRating = (
  BrewId,
  UserId,
  rating,
  CoffeeName,
  Grinding,
  WaterLevel,
  CreatedAt
) => {
  axios.post("/v1/api/rate", {
    BrewId: BrewId,
    UserId: UserId,
    rating: rating,
    CoffeeName: CoffeeName,
    Grinding: Grinding,
    WaterLevel: WaterLevel,
    CreatedAt: CreatedAt,
  });
};

// Update rating
const updateRating = (UserId, BrewId, rating) => {
  axios.put(`/v1/api/rate/${UserId}/${BrewId}`, { rating: rating });
};

// Fetch ratings to calculate average rating
const fetchRatings = (BrewId) => {
  return axios.get(`/v1/api/rate/average/${BrewId}`);
};

// Fetch all your ratings
const fetchYourRatings = (UserId) => {
  return axios.get(`/v1/api/rate/yourratings/${UserId}`);
};

// get all users from the server
const getUsers = () => {
  return axios.get("/v1/api/user");
};

// get a user from the server
const getUser = (UserId) => {
  return axios.get(`/v1/api/user/${UserId}`);
};

// update a user
const updateUser = (UserId, username, email, role) => {
  axios.patch(`/v1/api/user/${UserId}`, {
    username: username,
    email: email,
    role: role,
  });
};

// delete a user
const deleteUser = (UserId) => {
  axios.delete(`/v1/api/user/${UserId}`);
};

// register a new user
const registerUser = (username, email, password) => {
  return axios.post("/v1/api/auth/register", {
    username: username,
    email: email,
    password: password,
  });
};

// login user
const login = (username, password) => {
  return axios.post("/v1/api/auth/login", { username, password });
};

export {
  createBrew,
  createBean,
  getBrews,
  getAllBrews,
  getBeans,
  checkRating,
  postRating,
  updateRating,
  fetchRatings,
  getUsers,
  getUser,
  updateUser,
  deleteUser,
  fetchYourRatings,
  registerUser,
  login,
};
