import React, { useEffect } from "react";
import "./BrewUpdater.css";

// Components
import ThermosStatus from "../../components/ThermosStatus/ThermosStatus";

const BrewUpdater = (props) => {
  useEffect(() => {
    props.handlePage("Brew updater");
  });

  return (
    <div className="BrewUpdater">
      <ThermosStatus {...props.coffeeStatus} />
    </div>
  );
};

export default BrewUpdater;
