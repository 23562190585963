import React, { useState, useEffect } from "react";
import "./ThermosStatusBrew.css";
import { default as AuthContext } from "../../utils/AuthContextProvider";

// Components
import EditStars from "../EditStars/EditStars";

const ThermosStatusBrew = (props) => {
  const auth = React.useContext(AuthContext);
  const [YourStars, setYourStars] = useState(0);
  const [User, setUser] = useState(null);

  useEffect(() => {
    auth.user && setUser(auth.user);
  }, [auth.user]);

  const setStars = (item) => {
    setYourStars(item);
  };

  return (
    <div className="ThermosStatusBrew">
      <div className="info">
        <p>Coffee</p>
        <p id="tiny">{props.coffeeStatus.CoffeeType}</p>
      </div>
      <div className="info">
        <p>Grinding settings</p>
        <p id="tiny">{props.coffeeStatus.grindSetting}</p>
      </div>
      <div className="info">
        <p>Water level</p>
        <p id="tiny">{props.coffeeStatus.litersWater}</p>
      </div>

      {User && (
        <>
          <EditStars setStars={setStars}></EditStars>
          <button
            onClick={() => {
              props.setRating(YourStars);
            }}
          >
            Rate brew
          </button>
        </>
      )}
    </div>
  );
};

export default ThermosStatusBrew;
