// imports
import { useState, useEffect } from "react";
import { createBean } from "../../api/apiCalls";
import "./AddBeansForm.css";

// functional component to add coffeebeans via form
const AddBeansForm = (props) => {
  const [CoffeeBean, setCoffeeBean] = useState({
    name: "",
    brand: "",
    roast: "",
    nok: null,
    grams: null,
  });

  // Set initial state
  useEffect(() => {
    setCoffeeBean({ name: "", brand: "", roast: null, nok: null, grams: null });
  }, [props.coffeeBeans]);

  // handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, brand, roast, nok, grams } = CoffeeBean;

    // axios post request to backend
    try {
      const response = await createBean(name, brand, roast, nok, grams);
      if (!response.errors) {
        alert("Bean added!");
      }
    } catch (error) {
      alert("Error adding bean");
    }
  };

  // Control the form
  const handleChange = (e) => {
    e.preventDefault();
    setCoffeeBean({
      ...CoffeeBean,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form
      className="AddBeansForm"
      onSubmit={(e) => {
        handleSubmit(e);
      }}
    >
      <label htmlFor="name">Name</label>
      <input name="name" type="text" onChange={handleChange} required />
      <label htmlFor="brand">Brand</label>
      <input name="brand" type="text" onChange={handleChange} required />
      <label htmlFor="roast">Roast</label>
      <select name="roast" onChange={handleChange} required>
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
      </select>
      <label htmlFor="nok">NOK</label>
      <input name="nok" type="number" onChange={handleChange} required />
      <label htmlFor="grams">Grams</label>
      <input name="grams" type="number" onChange={handleChange} required />
      <input type="submit" value="Add Coffee Bean" />
    </form>
  );
};

export default AddBeansForm;
