import React, { useState, useEffect } from "react";
import "./MyRatings.css";
import { default as AuthContext } from "../../utils/AuthContextProvider";
import { fetchYourRatings } from "../../api/apiCalls";

// Components
import MyRatingsCard from "../../components/MyRatingsCard/MyRatingsCard";
import EditStarsPopup from "../../components/EditStarsPopup/EditStarsPopup";

const MyRatings = (props) => {
  const auth = React.useContext(AuthContext);

  const [YourRatings, setYourRatings] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [Popup, setPopup] = useState(false);
  const [PopupData, setPopupData] = useState({});

  // Fetch your ratings
  useEffect(() => {
    fetchYourRatings(auth.user.id).then((response) => {
      setYourRatings(response.data);
      setLoading(false);
    });
    props.handlePage("My ratings");
  }, [props, auth.user.id]);

  // Trigger edit rating popup
  const triggerPopup = (name, BrewId) => {
    setPopup(true);
    setPopupData({ name: name, BrewId: BrewId });
  };

  return (
    <div className="MyRatings">
      <p id="intro">
        Here you can find all brews you have rated yourself. The stars indicates
        the rating you have given each brew. Press the pen to edit a rating.
      </p>
      <div className="MyRatingsContainer">
        {!Loading &&
          YourRatings.map((item) => (
            <MyRatingsCard
              key={item.CoffeeName}
              data={item}
              triggerPopup={triggerPopup}
            />
          ))}
      </div>
      {Popup && (
        <EditStarsPopup
          name={PopupData.name}
          id={PopupData.BrewId}
          fetchData={props.fetchData}
        />
      )}
    </div>
  );
};

export default MyRatings;
