import React from "react";
import "./Header.css";
import logo from "../../images/coffee-cup.png";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../utils/AuthContextProvider";

// Components
import Hamburger from "../Hamburger/Hamburger";

const Header = (props) => {
  const auth = React.useContext(AuthContext);

  // Handles logout button
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/login");
  };

  return (
    <div className="Header">
      <img src={logo} alt="logo" />
      <h1>{props.title}</h1>
      <Hamburger handleHamburger={props.handleHamburger} />
      {auth.isAuth ? (
        <button onClick={auth.logoutUser}>Logout</button>
      ) : (
        <button onClick={handleClick}>Login</button>
      )}
    </div>
  );
};

export default Header;
