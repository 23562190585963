import React, { useState, useEffect } from "react";
import { createBrew } from "../../api/apiCalls";
import { default as AuthContext } from "../../utils/AuthContextProvider";
import "./UpdateCoffeeForm.css";

const UpdateCoffeeForm = (props) => {
  const auth = React.useContext(AuthContext);

  const [coffeeStatus, setCoffeeStatus] = useState({
    name: "",
    bean: "",
    grinding: null,
    liters: null,
  });

  // Set initial state
  useEffect(() => {
    setCoffeeStatus({ bean: props.coffeeBeans[0], grinding: 1, liters: 0.5 });
  }, [props.coffeeBeans, auth.user]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, bean, grinding, liters } = coffeeStatus;

    // Creates a new brew
    await createBrew({
      CoffeeName: name,
      CoffeeType: bean,
      grindSetting: grinding,
      litersWater: liters,
      User: auth.user.username,
    });

    // Updates existing brew
    const updatedCoffeeStatus = {
      CoffeeName: name,
      CoffeeType: bean,
      grindSetting: grinding,
      litersWater: liters,
      User: auth.user.username,
    };

    props.setUpdatedCoffeeStatus(updatedCoffeeStatus);
  };

  // Controls form state
  const handleChange = (e) => {
    e.preventDefault();
    setCoffeeStatus({
      ...coffeeStatus,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form
      className="UpdateCoffeeForm"
      onSubmit={(e) => {
        handleSubmit(e);
      }}
    >
      <label htmlFor="name">Name</label>
      <input name="name" type="text" onChange={handleChange} />
      <label htmlFor="bean">Bean type</label>
      <select name="bean" onChange={handleChange}>
        {props.coffeeBeans.map((bean) => (
          <option key={bean}>{bean}</option>
        ))}
      </select>
      <label htmlFor="grinding">Grinding level</label>
      <select name="grinding" onChange={handleChange}>
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>
        <option>6</option>
        <option>7</option>
      </select>
      <label htmlFor="liters">Liters</label>
      <select name="liters" onChange={handleChange}>
        <option>0.5</option>
        <option>1.1</option>
        <option>2.2</option>
      </select>
      <input type="submit" value="Create" id="submit"></input>
    </form>
  );
};

export default UpdateCoffeeForm;
