import React, { useState, useEffect } from "react";
import "./YourStars.css";
import { ReactComponent as Star } from "../../images/star.svg";

const YourStars = (props) => {
  const [YourStars, setYourStars] = useState([
    <Star key={1} fill="#D7A86E" />,
    <Star key={2} fill="#D7A86E" />,
    <Star key={3} fill="#D7A86E" />,
    <Star key={4} fill="#D7A86E" />,
    <Star key={5} fill="#D7A86E" />,
  ]);

  // Dislpays colored stars based on rating
  useEffect(() => {
    switch (props.rating) {
      case 1:
        setYourStars([
          SelectedStar(1),
          UnselectedStar(2),
          UnselectedStar(3),
          UnselectedStar(4),
          UnselectedStar(5),
        ]);
        break;
      case 2:
        setYourStars([
          SelectedStar(1),
          SelectedStar(2),
          UnselectedStar(3),
          UnselectedStar(4),
          UnselectedStar(5),
        ]);
        break;
      case 3:
        setYourStars([
          SelectedStar(1),
          SelectedStar(2),
          SelectedStar(3),
          UnselectedStar(4),
          UnselectedStar(5),
        ]);
        break;
      case 4:
        setYourStars([
          SelectedStar(1),
          SelectedStar(2),
          SelectedStar(3),
          SelectedStar(4),
          UnselectedStar(5),
        ]);
        break;
      case 5:
        setYourStars([
          SelectedStar(1),
          SelectedStar(2),
          SelectedStar(3),
          SelectedStar(4),
          SelectedStar(5),
        ]);
        break;
      default:
        break;
    }
  }, [props.rating]);

  const UnselectedStar = (item) => {
    return <Star key={item} fill="#D7A86E" />;
  };

  const SelectedStar = (item) => {
    return <Star key={item} fill="#8E3200" />;
  };

  return <div className="YourStars">{YourStars}</div>;
};

export default YourStars;
