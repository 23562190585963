import React, { useState } from "react";
import "./EditStars.css";
import { ReactComponent as Star } from "../../images/star.svg";

const EditStars = (props) => {
  // The star svg has a dynamic fill based on rating
  const [Stars, setStars] = useState([
    <Star
      key={1}
      onClick={() => {
        handleClick(1);
      }}
      fill="#D7A86E"
    />,
    <Star
      key={2}
      onClick={() => {
        handleClick(2);
      }}
      fill="#D7A86E"
    />,
    <Star
      key={3}
      onClick={() => {
        handleClick(3);
      }}
      fill="#D7A86E"
    />,
    <Star
      key={4}
      onClick={() => {
        handleClick(4);
      }}
      fill="#D7A86E"
    />,
    <Star
      key={5}
      onClick={() => {
        handleClick(5);
      }}
      fill="#D7A86E"
    />,
  ]);

  // Based on rating, display selected or unselected star
  const handleClick = (item) => {
    props.setStars(item);
    switch (item) {
      case 1:
        setStars([
          SelectedStar(1),
          UnselectedStar(2),
          UnselectedStar(3),
          UnselectedStar(4),
          UnselectedStar(5),
        ]);
        break;
      case 2:
        setStars([
          SelectedStar(1),
          SelectedStar(2),
          UnselectedStar(3),
          UnselectedStar(4),
          UnselectedStar(5),
        ]);
        break;
      case 3:
        setStars([
          SelectedStar(1),
          SelectedStar(2),
          SelectedStar(3),
          UnselectedStar(4),
          UnselectedStar(5),
        ]);
        break;
      case 4:
        setStars([
          SelectedStar(1),
          SelectedStar(2),
          SelectedStar(3),
          SelectedStar(4),
          UnselectedStar(5),
        ]);
        break;
      case 5:
        setStars([
          SelectedStar(1),
          SelectedStar(2),
          SelectedStar(3),
          SelectedStar(4),
          SelectedStar(5),
        ]);
        break;
      default:
        break;
    }
  };

  const UnselectedStar = (item) => {
    return (
      <Star
        key={item}
        onClick={() => {
          handleClick(item);
        }}
        fill="#D7A86E"
      />
    );
  };

  const SelectedStar = (item) => {
    return (
      <Star
        key={item}
        onClick={() => {
          handleClick(item);
        }}
        fill="#8E3200"
      />
    );
  };

  return <div className="EditStars">{Stars}</div>;
};

export default EditStars;
