// Edit user form that is rendered when the edit button is clicked
import React, { useState, useEffect } from "react";
import "./EditUserForm.css";
import { getUser, updateUser } from "../../api/apiCalls";
import close from "../../images/close.svg";

// functional component that renders the user data in a form, and updates it when submitted
const EditUserForm = (props) => {
  const [user, setUser] = useState({
    id: "",
    username: "",
    email: "",
    role: "",
  });

  // set the users state to the users returned from the api
  useEffect(() => {
    getUser(props.editUserId).then((res) => {
      // parse res data to get the users
      const { _id, username, email, role } = res.data.user[0];
      setUser({ _id, username, email, role });
    });
  }, [props.editUserId]);

  // function that handles the edit button
  const handleEditUser = (e) => {
    e.preventDefault();
    updateUser(user._id, user.username, user.email, user.role);
    props.handleEditUserId(null);
  };

  // function that handles the change event
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  return (
    <div className="EditUserForm">
      <form onSubmit={handleEditUser}>
        <div className="form-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            className="form-control"
            id="username"
            name="username"
            value={user.username}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={user.email}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="role">Role</label>
          <select
            className="form-control"
            id="role"
            name="role"
            value={user.role}
            onChange={handleChange}
          >
            <option value="user">User</option>
            <option value="admin">Admin</option>
          </select>
        </div>
        <button type="submit" className="btn btn-primary">
          Edit
        </button>
      </form>
      <button id="close" onClick={() => props.handleEditUserId(null)}>
        <img src={close} alt="exit"></img>
      </button>
    </div>
  );
};

export default EditUserForm;
