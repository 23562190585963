import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Outlet } from "react-router-dom";
import "./App.css";

// Components
import Navbar from "./components/Navbar/Navbar";
import Header from "./components/Header/Header";
import ThermosStatusBrew from "./components/ThermosStatusBrew/ThermosStatusBrew";
import ProtectedRoute from "./utils/ProtectedUserRoute";
import ProtectedAdminRoute from "./utils/ProtectedAdminRoute";
import UpdateCoffeeForm from "./components/UpdateCoffeeForm/UpdateCoffeeForm";

// Pages
import AddBeans from "./pages/AddBeans/AddBeans";
import BrewHistory from "./pages/BrewHistory/BrewHistory";
import BrewUpdater from "./pages/BrewUpdater/BrewUpdater";
import Dashboard from "./pages/Dashboard/Dashboard";
import Login from "./pages/Login/Login";
import ManageUsers from "./pages/ManageUsers/ManageUsers";
import MyRatings from "./pages/MyRatings/MyRatings";
import Register from "./pages/Register/Register";

// HOCified
import CoffeeHOC from "./hoc/CoffeeHoc";
const DashboardWithHOC = CoffeeHOC(Dashboard);
const BrewUpdaterWithHOC = CoffeeHOC(BrewUpdater);
const UpdateCoffeeFormWithHOC = CoffeeHOC(UpdateCoffeeForm);
const ThermosStatusBrewWithHOC = CoffeeHOC(ThermosStatusBrew);
const MyRatingsWithHOC = CoffeeHOC(MyRatings);
const BrewHistoryWithHOC = CoffeeHOC(BrewHistory);

const App = () => {
  const [NavbarActive, setNavbarActive] = useState(false);
  const [Page, setPage] = useState("dashboard");
  const [UpdatedCoffeeStatus, setUpdatedCoffeeStatus] = useState({});

  // Set page title
  const handlePage = (page) => {
    setPage(page);
  };

  // To refresh the coffeemug instantly when making a new brew
  const handleUpdatedCoffeeStatus = (data) => {
    setUpdatedCoffeeStatus(data);
  };

  // Handle hamburger click
  const handleHamburger = () => {
    NavbarActive ? setNavbarActive(false) : setNavbarActive(true);
  };

  return (
    <main>
      <Header title={Page} handleHamburger={handleHamburger} />
      <Navbar NavbarActive={NavbarActive} handleHamburger={handleHamburger} />
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route
            path="addbeans"
            element={<AddBeans handlePage={handlePage} />}
          />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route
            path="brewhistory"
            element={<BrewHistoryWithHOC handlePage={handlePage} />}
          />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route
            path="brewupdater"
            element={
              <div className="mainContainer">
                <BrewUpdaterWithHOC
                  handlePage={handlePage}
                  UpdatedCoffeeStatus={UpdatedCoffeeStatus}
                />
                <UpdateCoffeeFormWithHOC
                  setUpdatedCoffeeStatus={handleUpdatedCoffeeStatus}
                />
              </div>
            }
          />
        </Route>
        <Route
          path=""
          element={
            <div className="mainContainer">
              <DashboardWithHOC handlePage={handlePage} />
              <ThermosStatusBrewWithHOC />
            </div>
          }
        />
        <Route path="login" element={<Login handlePage={handlePage} />} />
        <Route element={<ProtectedAdminRoute />}>
          <Route
            path="manageusers"
            element={<ManageUsers handlePage={handlePage} />}
          />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route
            path="myratings"
            element={<MyRatingsWithHOC handlePage={handlePage} />}
          />
        </Route>
        <Route path="register" element={<Register handlePage={handlePage} />} />
        <Route path="*" element={<p>Nothing to see here...</p>} />
      </Routes>
      <Outlet />
    </main>
  );
};

export default App;
