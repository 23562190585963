import React from "react";
import { NavLink } from "react-router-dom";
import { default as AuthContext } from "../../utils/AuthContextProvider";
import { useNavigate } from "react-router-dom";
import "./Navbar.css";

const AuthConsumer = AuthContext.Consumer;

const Navbar = (props) => {
  const auth = React.useContext(AuthContext);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/login");
  };

  return (
    <div className={props.NavbarActive ? "NavbarVisible" : "Navbar"}>
      <AuthConsumer>
        {({ isAuth, user }) => (
          <>
            <ul>
              <NavLink
                to="/"
                className={(navData) =>
                  navData.isActive ? "active" : "NavLink"
                }
                onClick={props.handleHamburger}
              >
                <li>Dashboard</li>
              </NavLink>

              {isAuth && (
                <NavLink
                  to="/myratings"
                  className={(navData) =>
                    navData.isActive ? "active" : "NavLink"
                  }
                  onClick={props.handleHamburger}
                >
                  <li>My ratings</li>
                </NavLink>
              )}
              {isAuth && (
                <NavLink
                  to="/brewhistory"
                  className={(navData) =>
                    navData.isActive ? "active" : "NavLink"
                  }
                  onClick={props.handleHamburger}
                >
                  <li>Brew history</li>
                </NavLink>
              )}
              {isAuth && (
                <NavLink
                  to="/brewupdater"
                  className={(navData) =>
                    navData.isActive ? "active" : "NavLink"
                  }
                  onClick={props.handleHamburger}
                >
                  <li>Brew updater</li>
                </NavLink>
              )}
              {isAuth && user.role[0] === "admin" && (
                <NavLink
                  to="/manageusers"
                  className={(navData) =>
                    navData.isActive ? "active" : "NavLink"
                  }
                  onClick={props.handleHamburger}
                >
                  <li>Manage users</li>
                </NavLink>
              )}
              {isAuth && (
                <NavLink
                  to="/addbeans"
                  className={(navData) =>
                    navData.isActive ? "active" : "NavLink"
                  }
                  onClick={props.handleHamburger}
                >
                  <li>Add beans</li>
                </NavLink>
              )}
            </ul>
            <div className="user">
              {user ? (
                <p>Logged in as {user.username}</p>
              ) : (
                <p>Not logged in</p>
              )}
              {auth.isAuth ? (
                <button
                  onClick={() => {
                    handleClick();
                    props.handleHamburger();
                    auth.logoutUser();
                  }}
                >
                  Logout
                </button>
              ) : (
                <button
                  onClick={() => {
                    handleClick();
                    props.handleHamburger();
                  }}
                >
                  Login
                </button>
              )}
            </div>
          </>
        )}
      </AuthConsumer>
    </div>
  );
};

export default Navbar;
