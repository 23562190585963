import React, { useState, useEffect } from "react";
import "./BrewHistory.css";
import { default as AuthContext } from "../../utils/AuthContextProvider";
import { getAllBrews } from "../../api/apiCalls";

// Components
import BrewHistoryCard from "../../components/BrewHistoryCard/BrewHistoryCard";
import EditStarsPopup from "../../components/EditStarsPopup/EditStarsPopup";

const BrewHistory = (props) => {
  const auth = React.useContext(AuthContext);

  const [YourRatings, setYourRatings] = useState([]);
  const [YourRatingsFiltered, setYourRatingsFiltered] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [Popup, setPopup] = useState(false);
  const [PopupData, setPopupData] = useState({});
  const [SortDate, setSortDate] = useState("desc");
  const [FilterGrinding, setFilterGrinding] = useState("all");
  const [FilterWater, setFilterWater] = useState("all");

  useEffect(() => {
    getAllBrews().then((response) => {
      setYourRatings(response.data);
      setYourRatingsFiltered(response.data);
      setLoading(false);
    });

    props.handlePage("Brew history");
  }, [props, auth.user.id]);

  // Trigger popup for edit rating
  const triggerPopup = (name, grinding, waterLevel, createdAt, BrewId) => {
    setPopup(true);
    setPopupData({
      name: name,
      grinding: grinding,
      waterLevel: waterLevel,
      createdAt: createdAt,
      BrewId: BrewId,
    });
  };

  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    await getAllBrews().then((response) => {
      setYourRatings(response.data);
      setLoading(false);
    });

    let arr = YourRatings;

    // Handles the sorting and filtering
    if (FilterGrinding !== "all" && FilterWater === "all") {
      arr = arr.filter((data) => data.grindSetting === Number(FilterGrinding));
      sortByDate(arr);
    } else if (FilterWater !== "all" && FilterGrinding === "all") {
      arr = arr.filter((data) => data.litersWater === Number(FilterWater));
      sortByDate(arr);
    } else if (FilterGrinding !== "all" && FilterWater !== "all") {
      arr = arr.filter(
        (data) =>
          data.grindSetting === Number(FilterGrinding) &&
          data.litersWater === Number(FilterWater)
      );
      sortByDate(arr);
    } else if (FilterGrinding === "all" && FilterWater === "all") {
      sortByDate(arr);
    }
  };

  const sortByDate = (arr) => {
    const sortDateDesc = []
      .concat(arr)
      .sort((a, b) => Date.parse(b.CreatedAt) - Date.parse(a.CreatedAt));

    const sortDateAsc = []
      .concat(arr)
      .sort((a, b) => Date.parse(a.CreatedAt) - Date.parse(b.CreatedAt));

    SortDate === "desc" && setYourRatingsFiltered(sortDateDesc);
    SortDate === "asc" && setYourRatingsFiltered(sortDateAsc);
  };

  return (
    <div className="BrewHistory">
      <div className="container">
        <p id="intro">
          Here you can find every brew created on the platform. The stars
          indicates the brew's average rating. Press the pen to edit your rating
          for that brew.
        </p>
        <p id="intro">
          Brews you have already voted for is indicated by a star on the top
          left corner of the card.
        </p>

        <form onSubmit={(e) => handleSubmit(e)}>
          <h2>Filters</h2>
          <label htmlFor="sortdate">Sort by date created</label>
          <select onChange={(e) => setSortDate(e.target.value)} name="sortdate">
            <option value="desc">New to old</option>
            <option value="asc">Old to new</option>
          </select>
          <label htmlFor="sortgrinding">Filter by grind settings</label>
          <select
            onChange={(e) => setFilterGrinding(e.target.value)}
            name="sortgrinding"
          >
            <option value="all">All</option>
            <option value={1}>1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
          </select>
          <label htmlFor="sortwater">Filter by water level</label>
          <select
            onChange={(e) => setFilterWater(e.target.value)}
            name="sortwater"
          >
            <option value="all">All</option>
            <option value="0.5">0.5</option>
            <option value="1.1">1.1</option>
            <option value="2.2">2.2</option>
          </select>
          <input type="submit" value="Search" />
        </form>
        <div className="BrewHistoryContainer">
          {!Loading &&
            YourRatingsFiltered.map((item) => (
              <BrewHistoryCard
                key={item.CoffeeName}
                data={item}
                triggerPopup={triggerPopup}
              />
            ))}
        </div>
      </div>

      {Popup && (
        <EditStarsPopup
          name={PopupData.name}
          grinding={PopupData.grinding}
          waterLevel={PopupData.waterLevel}
          createdAt={PopupData.createdAt}
          id={PopupData.BrewId}
          fetchData={props.fetchData}
        />
      )}
    </div>
  );
};

export default BrewHistory;
