// imports
import "./CoffeeBeanList.css";
import { useState, useEffect } from "react";
import { getBeans } from "../../api/apiCalls";

// functional component that displays a list of coffee beans
const CoffeeBeanList = () => {
  const [coffeeBeans, setCoffeeBeans] = useState([]);

  // get the list of coffee beans from the api and set the state
  useEffect(() => {
    getBeans()
      .then((res) => {
        setCoffeeBeans(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // map through coffeeBean and display them in a list
  return (
    <div className="CoffeeBeanList">
      <h1>Coffee Beans</h1>
      <ul>
        {coffeeBeans.reverse().map((coffeeBean) => {
          return (
            <div key={coffeeBean._id} className="BeanCard">
              <h2>{coffeeBean.name}</h2>
              <p>Brand: {coffeeBean.brand}</p>
              <p>Roast: {coffeeBean.roast}</p>
              <p>NOK: {coffeeBean.nok}</p>
              <p>Grams: {coffeeBean.grams}</p>
            </div>
          );
        })}
      </ul>
    </div>
  );
};

export default CoffeeBeanList;
