import React, { useEffect, useState } from "react";
import "./ManageUsers.css";

// Components
import ManageUsersComponent from "../../components/ManageUsersComponent/ManageUsersComponent";
import EditUserForm from "../../components/EditUserForm/EditUserForm";

const ManageUsers = (props) => {
  useEffect(() => {
    props.handlePage("Manage users");
  }, [props]);

  // add editUserId to state
  const [editUserId, setEditUserId] = useState(null);

  // handle editUserId
  const handleEditUserId = (UserId) => {
    setEditUserId(UserId);
  };

  return (
    <div className="ManageUsers">
      <ManageUsersComponent editUserId={handleEditUserId} />
      {editUserId && (
        <EditUserForm
          editUserId={editUserId}
          handleEditUserId={handleEditUserId}
        />
      )}
    </div>
  );
};

export default ManageUsers;
